const Popup = ({ isOpen, onClose, caption, children }) => {
	if (!isOpen) {
		return null;
	}

	return <div className='btnPopupWrap' onClick={() => onClose()}>
		<button className='popupCloseBtn' onClick={() => onClose()}>
			<svg xmlns='http://www.w3.org/2000/svg' fill='#fff' width={24} height={24} viewBox='0 0 32 32'>
				<path d='M17.769 16l9.016-9.016c0.226-0.226 0.366-0.539 0.366-0.884 0-0.691-0.56-1.251-1.251-1.251-0.346 0-0.658 0.14-0.885 0.367v0l-9.015 9.015-9.016-9.015c-0.226-0.226-0.539-0.366-0.884-0.366-0.69 0-1.25 0.56-1.25 1.25 0 0.345 0.14 0.658 0.366 0.884v0l9.015 9.016-9.015 9.015c-0.227 0.226-0.367 0.539-0.367 0.885 0 0.691 0.56 1.251 1.251 1.251 0.345 0 0.658-0.14 0.884-0.366v0l9.016-9.016 9.015 9.016c0.227 0.228 0.541 0.369 0.888 0.369 0.691 0 1.251-0.56 1.251-1.251 0-0.347-0.141-0.661-0.369-0.887l-0-0z' />
			</svg>
		</button>

		<div className='popupContent' onClick={e => e.stopPropagation()}>
			{children}

			{caption && <p className='popupCaption' dangerouslySetInnerHTML={{ __html: caption }} />}
		</div>
	</div>;
};
export default Popup;