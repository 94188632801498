import { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom';


import Button from './Components/Common/Button';
import Style from './Components/Common/Style';
import Popup from './Components/Frontend/Popup';
import usePremium from './hooks/usePremium';
import './style.scss';

document.addEventListener('DOMContentLoaded', () => {
	const buttonEls = document.querySelectorAll('.wp-block-btn-button');
	buttonEls.forEach(buttonEl => {
		const nonce = JSON.parse(buttonEl.dataset.nonce);
		const role = JSON.parse(buttonEl.dataset.role);
		const loginUrl = JSON.parse(buttonEl.dataset.loginurl);
		const attributes = JSON.parse(buttonEl.dataset.attributes);
		const { cId, popup = {} } = attributes;
		const { content = '' } = popup;

		if(buttonEl){
			createRoot(buttonEl).render(<>
				<Style attributes={attributes} clientId={cId} />
	
				<RenderButton role={role} loginUrl={loginUrl} attributes={attributes} popupContent={<div className='content' dangerouslySetInnerHTML={{ __html: content }} />} nonce={nonce} />
			</>);
		}
		buttonEl?.removeAttribute('data-nonce');
		buttonEl?.removeAttribute('data-attributes');
	});
});

const RenderButton = ({ attributes, popupContent, nonce, role, loginUrl }) => {
	const { text, security = 'none', actionType, target, url, securityRole } = attributes;
	const buttonEl = useRef(null);
	const [securityPass, setSecurityPass] = useState(false);
	const [securityPop, setSecurityPop] = useState(false);

	const { isPremium } = usePremium(nonce);


	useEffect(() => {
		window['AOS']?.init();
	}, []);




	return <> <Button attributes={attributes} securityPass={securityPass} setSecurityPass={setSecurityPass} securityPop={securityPop} isPremium={isPremium} Popup={securityPass ? Popup : null} popupContent={popupContent} ref={buttonEl} onClick={val => {
		if ('none' === security) {
			if (actionType === 'popup') {
				setSecurityPass(true);
			} else {
				window.open(url, target);
				setSecurityPass(false)
			}
		} else {
			if (security == "login") {
				if (role == securityRole || securityRole == "default") {
					setSecurityPass(true);
				} else {
					setSecurityPop(val)
				}
			} else if (security == "password") {
				setSecurityPop(val)
			} else if (security == "email") {
				setSecurityPop(val)
			}

		}

	}} >
		{text && <span className='btnText' dangerouslySetInnerHTML={{ __html: text }} />}
	</Button>

		{securityPop && <SecurityPopup role={role} loginUrl={loginUrl} security={security} actionType={actionType} attributes={attributes} securityPass={securityPass} setSecurityPop={setSecurityPop} setSecurityPass={setSecurityPass} />}
	</>
}

const SecurityPopup = ({ security, attributes, setSecurityPass, setSecurityPop, actionType, loginUrl }) => {
	const { target, url, securityRole, securityPassword, cPostId } = attributes;
	const [passErrorMsg, setPassErrorMsg] = useState("Enter Password for Show Content");
	const [errorCheck, setErrorCheck] = useState(true);
	const [storePass, setStorePass] = useState("");
	const [storeEmail, setStoreEmail] = useState("");

	const formSubmit = () => {

		if (storePass == securityPassword) {
			if (actionType === 'popup') {
				setSecurityPass(true);
			} else {
				window.open(url, target);
				setSecurityPass(false)
			}
			setPassErrorMsg("Correct Password");
			setErrorCheck(true)
		} else {
			setSecurityPass(false)
			setPassErrorMsg("Incorrect Password");
			setErrorCheck(false)

		}

	}

	const emailSubmit = () => {
		jQuery('#submitButton').prop('disabled', true);
		setErrorCheck(true);
		jQuery.ajax({
			type: 'POST',
			// eslint-disable-next-line no-undef
			url: RestScriptVars.endpoint,
			data: {
				action: 'email_submit_from_user',
				email: storeEmail,
				postId: cPostId

			},
			success(response) {
				if (response.data.status == true) {
					if (actionType === 'popup') {
						setSecurityPass(true);
					} else {
						window.open(url, target);
						setSecurityPass(false)
					}
					setSecurityPop(false)
					jQuery('#submitButton').prop('disabled', false);
				} else {
					setErrorCheck(false)
				}

			},
			error() {
				setErrorCheck(false)
				jQuery('#submitButton').prop('disabled', false);
			}
		});
	}



	return <>


		<div className="content">
			{security == "password" && <h2 className="popup_h2">
				{errorCheck ? passErrorMsg : <span>Incorrect Password</span>}
			</h2>}
			{security == "login" && <h2 className="popup_h2">
				You have to login as  <span>{securityRole}</span>
			</h2>}
			{security == "email" && <h2 className="popup_h2">
				{errorCheck ? 'Enter Your Email here' : <span>We got an error try with a new email</span>}
			</h2>}


			{security == "password" && <>
				<div className="form-control">
					<label htmlFor="passwordLabel">Enter Password</label>
					<input value={storePass} onChange={c => { setStorePass(c.target.value) }} type="password" name="password" id="passwordLabel" />
				</div>
				<button type="button" onClick={formSubmit} className='button_ok password_button' >OK</button>
			</>
			}
			{security == "email" && <>
				<div className="form-control">
					<label htmlFor="emailLabel">Enter Email</label>
					<input value={storeEmail} onChange={c => { setStoreEmail(c.target.value) }} type="email" name="email" id="emailLabel" />
				</div>
				<button type="button" onClick={emailSubmit} id='submitButton' className='button_ok password_button' >Submit</button>
			</>
			}

			{security == "login" && <a href={loginUrl} target='_blank' className='button_login' rel="noreferrer" >Login</a>}

			<button type="button" onClick={() => setSecurityPop(false)} className="close-overlay">&times;</button>
		</div>

	</>


}